<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t('backoffice.classifiers.title') }}</h4>
        <b-button v-b-modal.new-classifier variant="primary" class="ml-1">
          {{ $t('backoffice.classifiers.new-type') }}
        </b-button>
      </div>
      <b-table
        v-if="types.length > 0"
        class="position-relative"
        :items="types"
        responsive
        :small="isSmall"
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="loading"
      >
        <!-- Column: Role -->
        <template #cell(type)="{ item }">
          <b-media class="d-flex align-items-center">
            <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
              <div class="ml-1">
                <p class="font-weight-bold mb-0 text-dark">
                  {{ translateTranslationTable($store.state.locale.currentLocale, item.name) }}
                </p>
              </div>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Style -->
        <template #cell(style)="{ item }">
          <b-link :to="getClassifierLocation(item)" class="text-dark d-flex ml-1">
            <div
              class="preview--classifier"
              :style="
                `background-color: ${item.backgroundColor}; border-radius: 1.2rem; padding: 0.10rem 0.40rem; min-width: 90px`
              "
            >
              <small
                :style="`color: ${item.fontColor}`"
                class="font-weight-bolder"
              >{{ translateTranslationTable($store.state.locale.currentLocale, item.name) }}
              </small>
            </div>
          </b-link>
        </template>

        <!-- Column: Description -->
        <template #cell(description)="{ item }">
          <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
            <div class="text-nowrap">
              <p class="mb-0 ml-1 pl-25">
                {{ translateTranslationTable($store.state.locale.currentLocale, item.description) }}
              </p>
            </div>
          </b-link>
        </template>
        <!-- Column: Model -->
        <template #cell(model)="{ item }">
          <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
            <div class="text-nowrap">
              <span v-if="Array.isArray(item.modelType)">
                <p v-for="(typeModle, i) in item.modelType" class="mb-0 ml-1 pl-50" :key="i">
                  {{ model(typeModle) }}
                </p>
              </span>
              <p v-else class="mb-0 ml-1 pl-50">
                {{ getModelType(item) }}
              </p>
            </div>
          </b-link>
        </template>
        <!-- Column: Global -->
        <template #cell(global)="{ item }">
          <div :to="getClassifierLocation(item)" class="text-dark ml-1 pl-50">
            <b-badge pill variant="success" v-if="item.isSharedGlobally">Global</b-badge>
          </div>
        </template>
        <!-- Column: Num. classificators -->
        <template #cell(num)="{ item }">
          <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
            <div class="text-nowrap" style="max-width: 190px">
              <p class="mb-0 ml-2 ml-md-3 pl-md-5 text-center">
                {{ item.classifiers }}
              </p>
            </div>
          </b-link>
        </template>
      </b-table>
      <div v-else class="horizontal-placeholder d-block">
        <div>
          <img :src="classifiersPlaceholder" class="d-block">
        </div>
        <div>
          <p class="text-primary text-center">
            {{ $t('available.message', { itemName: $t('backoffice.classifiers.name') }) }}
          </p>
        </div>
      </div>

      <!-- New type modal -->
      <b-modal
        id="new-classifier"
        :title="$t('backoffice.classifiers.new-type')"
        :ok-title="$t('form.actions.save')"
        ok-only
        :ok-disabled="isDisabled"
        @hidden="resetForm()"
        @shown="addModelType()"
        @ok="saveClassifierType()"
      >
        <template #modal-header>
          <language-selector-header 
            :title="isEditing ? $t('backoffice.types.edit-type') : $t('backoffice.types.create-type')" 
            @closeModal="closeModal" 
            @selectLanguage="(language)=>selectedLanguage = language"
          />
        </template>
        <b-form-group class="flex-grow-1 mb-2">
          <label for="rol-name" class="font-weight-bold"> 
            {{ $t('backoffice.roles.name') }}*
          </label>
          <b-form-input
            v-model="currentClassifier.name[selectedLanguage]"
            class="mt-50"
            :value-placeholder="$t('layouts.widget-name')"
            label-for="name"
            :state="isValid"
            @input="validator()"
          />
          <small v-if="isValid === false" class="text-danger mt-25">
            {{ $t('backoffice.classifiers.validator-message') }}
          </small>
        </b-form-group>
        <!-- Description -->
        <b-form-group label-for="description">
          <label class="font-weight-bold">{{ $t('backoffice.roles.description') }}</label>
          <b-form-textarea
            v-model="currentClassifier.description[selectedLanguage]"
            class="mt-50"
            :value-placeholder="$t('layouts.widget-name')"
            :is-description="true"
            label-attribute="description"
          />
        </b-form-group>
        <b-form-group>
          <label for="height" class="font-weight-bold">
            {{ $t('backoffice.classifiers.multiple-info') }}
          </label>
          <!-- Multiple checkbox -->
          <b-form-checkbox v-model="currentClassifier.multiple" switch />
        </b-form-group>

        <!-- Models -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold"> 
            Apps*
          </label>
          <v-select
            v-model="currentClassifier.modelType"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="true"
            :label="locale"
            :get-option-label="(a) => a.addon[locale] || Object.values(a.addon)[0]"
            :options="modelOptions"
          />
        </b-form-group>
        <b-form-group>
          <label for="height" class="font-weight-bold">{{ $t('backoffice.classifiers.share-subcommunities') }}</label>
          <!-- Share subcommunities -->
          <b-form-checkbox v-model="currentClassifier.share" switch />
        </b-form-group>
        <b-row>
          <b-col>
            <!-- Background color -->
            <b-form-group>
              <label for="height" class="font-weight-bold">{{ $t('backoffice.classifiers.background-color') }}</label>
              <b-form-input
                id="height"
                v-model="currentClassifier.backgroundColor"
                type="color"
                class="banner-input"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- Font color -->
            <b-form-group>
              <label for="height" class="font-weight-bold">{{ $t('backoffice.classifiers.color') }}</label>
              <b-form-input
                id="height"
                v-model="currentClassifier.fontColor"
                type="color"
                class="banner-input"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="preview my-1">
          <p class="preview--title">
            {{ $t('backoffice.classifiers.preview') }}
          </p>
          <div
            class="preview--classifier d-block mx-auto my-1"
            :style="`background-color: ${currentClassifier.backgroundColor}; border-radius: 1.2rem;`"
          >
            <span
              :style="`color: ${currentClassifier.fontColor}`"
              class="font-weight-bolder"
            >{{
              translateTranslationTable($store.state.locale.currentLocale, currentClassifier.name) ||
                $t('backoffice.classifiers.new-classifier')
            }}
            </span>
          </div>
        </div>
      </b-modal>
    </b-card>
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ClassifiersPlaceholder from '@/assets/images/placeholders/light/classifiers.svg';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';

export default {
  name: 'Classifiers',
  components: {
    BackofficeContainer,
    vSelect,
    LanguageSelectorHeader,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentClassifier: {
        name: {},
        description: {},
        modelType: [],
        backgroundColor: '#5dbfc9',
        fontColor: '#ffffff',
        multiple: false,
        share: false,
      },
      isValid: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      selectedLanguage: this.currentLocale,
      isEditing: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return Object.values(this.currentClassifier.name).length === 0;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      return this.$store.getters.classifiersType.unpaginated;
    },
    total() {
      return this.types.length;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'payment',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app);
        }
      });
      return newObj;
    },
    fields() {
      return [
        {
          key: 'type',
          label: this.$t('backoffice.analytics.data-type'),
        },
        {
          key: 'style',
          label: this.$t('backoffice.classifiers.preview'),
        },
        {
          key: 'description',
          label: this.$t('backoffice.roles.description'),
        },
        {
          key: 'model',
          label: this.$tc('backoffice.classifiers.model', 1),
        },
        {
          key: 'global',
          label: 'Global',
        },
        {
          key: 'num',
          label: this.$t('backoffice.classifiers.num-classifiers'),
        },
      ];
    },
    classifiersPlaceholder() {
      return ClassifiersPlaceholder;
    },
  },
  async created() {
    await this.fetchClassifiersType();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    await this.getModelTypes();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.selectedLanguage, field);
    },
    async fetchClassifiersType() {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/classifierTypes',
        customName: 'classifiersType',
        forceAPICall: true,
        page: 1,
        perPage: 1000,
      });
    },
    async saveClassifierType() {
      try {
        if (this.currentClassifier.name === '' || this.currentClassifier.name === {} || this.isValid === false) {
          this.notifyError(this.$t('backoffice.classifiers.messages.error'));
        } else {
          const classifier = {
            name: this.currentClassifier.name,
            description: this.currentClassifier.description,
            modelType: Array.isArray(this.currentClassifier.modelType)
              ? this.currentClassifier.modelType.map((item) => item.addonSingular)
              : '',
            backgroundColor: this.currentClassifier.backgroundColor,
            fontColor: this.currentClassifier.fontColor,
            multiple: this.currentClassifier.multiple ? 1 : 0,
            sharedGlobally: this.currentClassifier.share ? 1 : 0,
          };
          await this.$store.dispatch('createItem', {
            item: {
              itemType: 'backoffice/classifierTypes',
              customName: 'classifiersType',
              requestConfig: {
                ...classifier,
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.classifiers.messages.success'));
          this.page = 1;
          this.fetchClassifiersType();
        }
      } catch {
        this.notifyError(this.$t('backoffice.classifiers.messages.error'));
      }
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    validator() {
      this.isValid = this.types.every((type) => type.name[this.locale] !== this.currentClassifier.name[this.locale]);
    },
    getClassifierLocation(classifier) {
      return {
        name: 'classifier-details',
        params: { id: classifier.key, communityId: this.$route.params.communityId },
      };
    },
    resetForm() {
      this.currentClassifier = {
        name: {},
        description: {},
        modelType: '',
        backgroundColor: '#5dbfc9',
        fontColor: '#ffffff',
        multiple: false,
      };
      this.isValid = null;
    },
    translateTranslationTable,
    async getModelTypes() {
      await this.$store.dispatch('getModelTypes');
    },
    getModelType(type) {
      let model = '';
      if (Array.isArray(type.modelType)) {
        model = this.$store.getters.modelTypes.filter((item) => type.modelType.includes(item?.addon.en));
        model = model.map((item) => item.addon[this.locale]);
      } else {
        model = type.modelType
          ? this.$store.getters.modelTypes.filter(
            (item) => item?.addon.en === (type?.modelType.en || type?.modelType),
          )[0]?.addon[this.locale]
          : '';
      }
      return model;
    },
    addModelType() {
      this.currentClassifier.modelType = [];
      //this.currentClassifier.modelType.push(this.modelOptions[0]);
    },
    model(typeModel) {
      let model = [];
      if (Array.isArray(this.type?.modelType)) {
        model = this.$store.getters.modelTypes.filter((item) => typeModel.includes(item?.addonSingular));
        return model.map((item) => item?.addon[this.locale]);
      }
      return this.$store.getters.modelTypes.filter((item) => item?.addonSingular === typeModel)[0]?.addon[
        this.locale
      ];
    },
    closeModal() {
      this.$bvModal.hide('new-classifier');
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type p:first-letter {
  text-transform: uppercase;
}
.preview {
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 1.2rem;
  padding: 1rem;
  position: relative;
  &--classifier {
    padding: 0.3rem 0.5rem;
    width: fit-content;
    text-align: center;
    border-radius: 0.358rem;
  }
  &--title {
    margin-top: 0;
    margin-bottom: 1rem;
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 0.5rem;
    background-color: #ffffff;
  }
}
</style>